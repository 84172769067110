import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2870615501/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "forms"
    }}>{`Forms`}</h1>
    <hr />
    <h2 {...{
      "id": "fetchforms"
    }}>{`fetchForms`}</h2>
    <h3 {...{
      "id": "params"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* kappSlug -> string = (required) slug of the kapp the forms are in
`}</code></pre>
    <h3 {...{
      "id": "resolves"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* forms -> Form[] = an array of forms
* count -> number = number of forms matching
* nextPageToken -> string = page token if there are matches beyond the limit
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { fetchForms } from '@kineticdata/react';

fetchForms({ kappSlug }).then(({ forms }) => console.log(forms));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "fetchform"
    }}>{`fetchForm`}</h2>
    <h3 {...{
      "id": "params-1"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* formSlug -> string = (required) slug of the form
* kappSlug -> string = (required) slug of the kapp the form is in
`}</code></pre>
    <h3 {...{
      "id": "resolves-1"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* form -> Form{} = a form object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-1"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { fetchForm } from '@kineticdata/react';

fetchForm({ kappSlug, formSlug }).then(({ form }) => console.log(form));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "updateform"
    }}>{`updateForm`}</h2>
    <h3 {...{
      "id": "params-2"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* formSlug -> string = (required) slug of the form
* form -> Form{} = (required) the updated form data to send
* kappSlug -> string = (required) slug of the kapp the form is in
`}</code></pre>
    <h3 {...{
      "id": "resolves-2"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* form -> Form{} = the updated form object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-2"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { updateForm } from '@kineticdata/react';

updateForm({ kappSlug, formSlug, form: formWithChanges }).then(({ form }) =>
  console.log(form),
);
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "createform"
    }}>{`createForm`}</h2>
    <h3 {...{
      "id": "params-3"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* formSlug -> string = (required) slug of the form
* form -> Form{} = (required) the form data to send
* kappSlug -> string = (required) slug of the kapp the form is in
`}</code></pre>
    <h3 {...{
      "id": "resolves-3"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* form -> Form{} = the created form object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-3"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { createForm } from '@kineticdata/react';

createForm({ kappSlug, formSlug, form }).then(({ form }) => console.log(form));
`}</code></pre>
    <br />
    <hr />
    <h2 {...{
      "id": "deleteform"
    }}>{`deleteForm`}</h2>
    <h3 {...{
      "id": "params-4"
    }}>{`Params`}</h3>
    <pre><code parentName="pre" {...{}}>{`* formSlug -> string = (required) slug of the form
* kappSlug -> string = (required) slug of the kapp the form is in
`}</code></pre>
    <h3 {...{
      "id": "resolves-4"
    }}>{`Resolves`}</h3>
    <pre><code parentName="pre" {...{}}>{`* form -> Form{} = the deleted form object
* error -> ErrorResponse{} = an error object on failure
`}</code></pre>
    <h3 {...{
      "id": "example-4"
    }}>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { deleteForm } from '@kineticdata/react';

deleteForm({ kappSlug, formSlug }).then(({ form }) => console.log(form));
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      